import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Switch, Route } from 'react-router-dom';
import { event } from 'react-fullstory';
import { SelectOptionGroup, SelectOption } from '@spoiler-alert/ui-library';
import HolisticsDashboardIframe from '../components/dashboards/holistics-dashboard-iframe';
import { DASHBOARD_LOCATIONS, DASHBOARD_TYPES } from '../enums/holistics-dashboard-enums';
import { HolisticsDashboardsQuery } from '../graphql/queries';
import { TitleService } from '../services';
import { Breadcrumbs } from '../store';
import routePaths from '../route-paths';

const HolisticsDashboard = ({ locationDefault, basePath, history }) => {
  const [defaultDashboard, setDefaultDashboard] = useState(null);
  const { data, loading } = useQuery(HolisticsDashboardsQuery);

  useEffect(() => {
    let title = 'Dashboard';
    let url = '/';
    if (locationDefault === DASHBOARD_LOCATIONS.REPORTS_TAB) {
      title = 'Reports';
      url = routePaths.reportsDashboard;
    }
    TitleService.setTitles(title);
    Breadcrumbs.set([
      {
        url,
        title,
      },
    ]);
  }, []);

  const dashboards = data?.holisticsDashboardsQuery || [];

  useEffect(() => {
    if (dashboards.length > 0 && locationDefault) {
      const defaultDashboards = dashboards.filter((d) => d.defaultForLocation === DASHBOARD_LOCATIONS[locationDefault]);
      let _activeDash = defaultDashboards[0];
      if (defaultDashboards.length > 1) {
        const custom = defaultDashboards.find((dd) => dd.type === DASHBOARD_TYPES.CUSTOM);
        if (custom) _activeDash = custom;
      } else {
        [_activeDash] = dashboards;
      }
      event('Viewed Holistics Dashboard', { dashboardName: _activeDash.name, defaultViewOnPageLoad: true });
      setDefaultDashboard(_activeDash);
    }
  }, [dashboards, locationDefault]);

  const dropdownOptions = useMemo(() => {
    const coreReports = [];
    const customReports = [];
    if (dashboards.length > 0) {
      [...dashboards]
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        .forEach((d) => {
          if (d.type === DASHBOARD_TYPES.CORE) coreReports.push(d);
          if (d.type === DASHBOARD_TYPES.CUSTOM) customReports.push(d);
        });
    }
    const options = [];
    if (coreReports.length > 0)
      options.push(
        <SelectOptionGroup key="core" name="CORE" search>
          {coreReports.map((r) => (
            <SelectOption key={r.reportId} value={r.reportId}>
              {r.name}
            </SelectOption>
          ))}
        </SelectOptionGroup>
      );
    if (customReports.length > 0)
      options.push(
        <SelectOptionGroup key="custom" name="CUSTOM" search>
          {customReports.map((r) => (
            <SelectOption key={r.reportId} value={r.reportId}>
              {r.name}
            </SelectOption>
          ))}
        </SelectOptionGroup>
      );
    return options;
  }, [dashboards]);

  const handleReportChange = (options) => {
    if (options.length > 0) {
      let url = '';
      if (options[0].value === defaultDashboard.reportId) url = basePath;
      else url = `${basePath}r/${options[0].value}`;
      history.push(url);
    }
  };

  return (
    <Switch>
      <Route
        exact
        path={basePath}
        render={({ match }) => (
          <HolisticsDashboardIframe
            match={match}
            loading={loading}
            dashboards={dashboards}
            defaultDashboardId={defaultDashboard?.reportId}
            handleReportChange={handleReportChange}
            dropdownOptions={dropdownOptions}
          />
        )}
      />
      <Route
        exact
        path={`${basePath}r/:reportId`}
        render={({ match }) => (
          <HolisticsDashboardIframe
            match={match}
            loading={loading}
            dashboards={dashboards}
            defaultDashboardId={defaultDashboard?.reportId}
            handleReportChange={handleReportChange}
            dropdownOptions={dropdownOptions}
          />
        )}
      />
    </Switch>
  );
};

HolisticsDashboard.propTypes = {
  locationDefault: PropTypes.string,
  basePath: PropTypes.string,
  history: PropTypes.object,
};

export default HolisticsDashboard;

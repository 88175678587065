export default {
  legacyDashboard: 'LEGACY DASHBOARD',
  holisticsDashboard: 'HOLISTICS DASHBOARD',
  acceptAllOffers: 'ACCEPT ALL OFFERS',
  donationsv2: 'DONATIONS V2',
  archiveInventory: 'ARCHIVE INVENTORY',
  customerProfiles: 'CUSTOMER PROFILES',
  distributionListSettings: 'DISTRIBUTION LIST SETTINGS',
  companyLogo: 'COMPANY LOGO',
  offerDueDate: 'OFFER DUE DATE',
  trucklaneView: 'TRUCKLANE VIEW',
  showNonProdSidebar: 'SHOW NON PROD SIDEBAR',
  historicalOffers: 'HISTORICAL OFFERS',
  marketplaceBidStrength: 'MARKETPLACE BID STRENGTH',
  virtualizedDataTable: 'VIRTUALIZED DATA TABLE',
  offerShift: 'OFFER SHIFT',
  reservePriceV2: 'RESERVE PRICE V2',
};

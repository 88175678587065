import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Theme, Search, Button, AcceptOffersIcon, Timing, AlertService, usePolling, EmptyStateIcon } from '@spoiler-alert/ui-library';
import { useMutation, useQuery } from '@apollo/client';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { AwardSummaryQuery, OfferReviewPollingQuery, SuggestedOfferShiftsByBuyerQuery } from '../../graphql/queries';
import StaticLoading from '../static-loading';
import { acceptOffer } from '../../graphql/mutations';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';
import deleteCachedFieldsOnListedInventoriesQuery from '../../apollo/cache-helpers/delete-cached-fields-on-listed-inventories-query';
import RecommendedOfferShifts from '../../components/banners/recommended-offer-shifts';
import OfferReviewBuyerCard from './offer-review-buyer-card';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    borderBottom: `1px ${Theme.borderColor} solid`,
    padding: '16px 0px',
    '&:first-child': {
      padding: '0px 0px 16px 0px',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 0px 24px',
    '& button': {
      marginLeft: 8,
    },
  },
  offerReviewSearch: {
    width: 500,
    marginRight: 'auto',
  },
  buttonIcon: {
    '& svg': {
      width: '24px !important',
      height: '24px !important',
    },
  },
  loader: {
    margin: 'calc(50vh - 118px) auto',
    textAlign: 'center',
  },
  emptyState: {
    padding: '24px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyStateHeader: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 10,
  },
  emptyStateText: {
    fontSize: 16,
  },
});

const exportStatusStringMap = {
  IN_PROGRESS: 'ACCEPTANCE IN PROGRESS',
  COMPLETE: 'ACCEPTED',
  NOT_IN_PROGRESS: 'NOT ACCEPTED',
  STARTED: 'PENDING',
};

const UI_LOCK_STATUS = {
  OPEN: 'OPEN',
  LOCKED: 'LOCKED',
};

const OfferReviewV2 = ({ user, history }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const { data: suggestedOfferShiftResults, loading: suggestedOfferShiftLoading } = useQuery(SuggestedOfferShiftsByBuyerQuery, {
    fetchPolicy: 'network-only',
  });
  const { data: awardSummaryResults, loading: awardSummaryLoading, refetch: refetchAwardSummary } = useQuery(AwardSummaryQuery);
  const [acceptOfferMutation, { loading: accepting }] = useMutation(acceptOffer);

  const statusPolling = usePolling({
    query: OfferReviewPollingQuery,
    poll: true,
    responseKey: 'offerReviewPollingQuery',
    idleInterval: 10000,
    activeInterval: 1000,
    isInProgress: () => accepting,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    TitleService.setTitles('Offer Review');
    Breadcrumbs.set([
      {
        url: routePaths.review,
        title: 'Offer Review',
      },
    ]);
  }, []);

  const buyerPollingMap = useMemo(() => {
    const statusMap = new Map();
    if (statusPolling) {
      statusPolling?.exportStatuses.forEach((e) => {
        statusMap.set(e.buyerSiteName, e.exportStatus);
      });
    }
    return statusMap;
  }, [statusPolling]);

  const noOffersToAccept = () =>
    (!statusPolling || statusPolling?.uiLockStatus === UI_LOCK_STATUS.OPEN) &&
    !awardSummaryResults?.currentUserQuery.getAwardSummary.results?.filter((result) => {
      return exportStatusStringMap[result.exportStatus] === exportStatusStringMap.NOT_IN_PROGRESS;
    }).length;

  const acceptAllDisabled = !(statusPolling?.uiLockStatus !== UI_LOCK_STATUS.LOCKED) || accepting || noOffersToAccept();

  const handleSearch = Timing.debounce((text) => setSearchText(text), 300);

  const handleAcceptAll = Timing.throttle(async () => {
    let buyerList = [];
    if (awardSummaryResults)
      buyerList = awardSummaryResults?.currentUserQuery.getAwardSummary.results.map((buyer) => ({
        ...buyer,
        _id: `${buyer.transactionLocationName}-${buyer.siteName}`,
      }));
    const buyerSiteIds = buyerList.filter((buyer) => buyerPollingMap.get(buyer.siteName) === 'NOT_IN_PROGRESS').map((b) => b.siteId);
    const { data } = await acceptOfferMutation({
      variables: { buyerSiteIds, acceptAll: true },
      update: (cache) => {
        deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters', 'getAwardSummary']);
        deleteCachedFieldsOnListedInventoriesQuery(cache);
      },
    });

    if (data.acceptOffer.errors.length) {
      AlertService.alert({
        type: 'warning',
        message: (
          <span>
            {'Sorry there was an error accepting all offers. If this problem persists, please contact a Spoiler Alert Administrator to help you.'}
          </span>
        ),
      });
      refetchAwardSummary();
    }
  }, 1000);

  const cols = useMemo(() => {
    const ignoredFields = ['transactionLocationName', 'logisticsTerm', 'siteName'];
    const dtpCols = [...getColumnsFromDataTableProfile('Offer Comp Buyer Flyup and Offer Review', user.site.dataTableProfiles)];
    return dtpCols
      .map((col) => {
        if (ignoredFields.includes(col.field)) return null;
        return col;
      })
      .filter((empties) => !!empties);
  }, []);

  const shiftSuggestions = useMemo(() => {
    if (suggestedOfferShiftResults?.suggestedOfferShiftsByBuyerQuery?.errors?.length)
      AlertService.alert({
        type: 'warning',
        message:
          'There was an unknown error while trying to load opportunities. Refresh this page, and if the issue persists, contact support. Offers can still be accepted.',
      });
    const shiftSuggestionMap = new Map();
    if (suggestedOfferShiftResults?.suggestedOfferShiftsByBuyerQuery?.buyers?.length) {
      suggestedOfferShiftResults?.suggestedOfferShiftsByBuyerQuery.buyers.forEach((buyer) => {
        shiftSuggestionMap.set(buyer._id, buyer);
      });
    }
    return shiftSuggestionMap;
  }, [suggestedOfferShiftResults]);

  const groupBuyerData = (currentBuyer, newBuyer) => {
    currentBuyer.acceptedOffer += newBuyer.acceptedOffer;
    currentBuyer.casesAwarded += newBuyer.casesAwarded;
    currentBuyer.totalCost += newBuyer.totalCost;
    currentBuyer.cubeAdjustedWeightAwarded += newBuyer.cubeAdjustedWeightAwarded;
    currentBuyer.donatedCost += newBuyer.donatedCost;
    currentBuyer.netObsolescenceImpact += newBuyer.netObsolescenceImpact;
    currentBuyer.originalQuantity += newBuyer.originalQuantity;
    currentBuyer.palletsAwarded += newBuyer.palletsAwarded;
    currentBuyer.priceOfInventoryAwarded += newBuyer.priceOfInventoryAwarded;
    currentBuyer.poundsAwarded += newBuyer.poundsAwarded;
    currentBuyer.totalCases += newBuyer.totalCases;
    currentBuyer.totalPrice += newBuyer.totalPrice;
    currentBuyer.totalWeight += newBuyer.totalWeight;
    currentBuyer.writeOffPrice += newBuyer.writeOffPrice;
    currentBuyer.writeOffPriceOfInventoryAwarded += newBuyer.writeOffPriceOfInventoryAwarded;
    currentBuyer.unitPriceRecoveryRate += newBuyer.unitPriceRecoveryRate;
    currentBuyer.costRecoveryRate = (currentBuyer.acceptedOffer / currentBuyer.totalCost) * 100;
    currentBuyer.discountPercent = (1 - currentBuyer.acceptedOffer / currentBuyer.totalPrice) * 100;
    currentBuyer.writeOffDiscountPercent = 1 - currentBuyer.acceptedOffer / currentBuyer.writeOffPriceOfInventoryAwarded;
  };

  const sortedBuyerOffers = useMemo(() => {
    const buyerMap = new Map();
    if (awardSummaryResults?.currentUserQuery.getAwardSummary.results.length) {
      JSON.parse(JSON.stringify(awardSummaryResults?.currentUserQuery.getAwardSummary.results)).forEach((result) => {
        if (buyerMap.has(result.siteName)) return groupBuyerData(buyerMap.get(result.siteName), result);
        return buyerMap.set(result.siteName, result);
      });
      return [...buyerMap.values()].sort((a, b) => {
        const nameA = a.siteName.toUpperCase();
        const nameB = b.siteName.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }
    return [];
  }, [awardSummaryResults]);

  const zeroState = () => {
    return (
      <div className={classes.emptyState}>
        <EmptyStateIcon />
        <div className={classes.emptyStateHeader}>No offers have been awarded yet.</div>
        <div className={classes.emptyStateText}>When you award offers, they will show up here.</div>
      </div>
    );
  };

  const buyerCard = useMemo(() => {
    if (!awardSummaryLoading && !suggestedOfferShiftLoading) {
      const buyerCards = [];
      const searchRegex = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
      sortedBuyerOffers.forEach((buyerData) => {
        const matchesSearch = searchText.trim() !== '' ? searchRegex.test([buyerData.siteName, buyerData.transactionLocationName]) : true;
        if (matchesSearch)
          buyerCards.push(
            <div key={buyerData.siteName} className={classes.card}>
              <OfferReviewBuyerCard
                buyerData={buyerData}
                status={buyerPollingMap.get(buyerData.siteName)}
                history={history}
                columns={cols}
                shiftSuggestions={shiftSuggestions.get(buyerData.siteId)}
                hasShiftSuggestions={shiftSuggestions.has(buyerData.siteId)}
              />
            </div>
          );
      });
      return buyerCards.length ? buyerCards : zeroState();
    }
    return <StaticLoading classes={{ container: classes.loader }} />;
  }, [awardSummaryLoading, suggestedOfferShiftLoading, sortedBuyerOffers, shiftSuggestions, buyerPollingMap, searchText]);

  const displayShiftBanner = () => {
    let display = false;
    sortedBuyerOffers.forEach((buyerData) => {
      if (shiftSuggestions.has(buyerData.siteId) && buyerData.exportStatus === 'NOT_IN_PROGRESS') display = true;
    });
    return display;
  };

  return (
    <div>
      <div className={classes.actionBar}>
        <Search onChange={handleSearch} className={classes.offerReviewSearch} />
        <Button
          classes={{ iconHolder: classes.buttonIcon }}
          primary
          disabled={acceptAllDisabled}
          loading={accepting}
          onClick={handleAcceptAll}
          icon={AcceptOffersIcon}
          loadingText="Accepting All Offers"
          cypressTag="accept-all-offers-button"
        >
          Accept All Offers
        </Button>
      </div>
      {displayShiftBanner() && <RecommendedOfferShifts />}
      {buyerCard}
      <div id="showScroll"></div>
    </div>
  );
};

OfferReviewV2.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
};

export default OfferReviewV2;
